import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import pic04 from '../assets/images/trauerhalle.jpg'
import Header from '../components/Header'
import { graphql, Link } from 'gatsby'
import Gallery from '@browniebroke/gatsby-image-gallery'

class Begleitung extends React.Component {
  constructor(props) {
    super(props)

    this.speaker = props.data.speaker.edges.map(({ node }) => node.childImageSharp);
  }

  render() {

    return (
      <Layout>
        <Helmet title="Abschiednahme und Trauerfeier | Bestattungshaus Bartko" />

        <Header isChildPage={true} />
        <div id="main">
          <section id="content" className="main">
            <span className="image main"><img src={pic04} alt="" /></span>

            <h1>Abschiednahme und Trauerfeier</h1>
            <p>Unsere hauseigene Trauerhalle bietet Platz für 30 bis 60 Personen. <br />
              Trauerfeier und Aufbahrung können so in einem würdevollen Rahmen stattfinden.
            </p>
            <p>Wir bieten Ihnen weiterhin an:</p>
            <ul>
              <li>Auswahl und Anfertigung Trauerfloristik</li>
              <li>Musikalische Umrahmung der Trauerfeier</li>
              <li>Kondolenzliste auslegen</li>
              <li>Halten von Trauerreden (Pfarrer oder hauseigene Redner)</li>
              <li>Drucken von Trauer- und Danksagungskarten</li>
              <li>Traueranzeigen und Danksagungen in regionalen und überregionalen Tageszeitungen</li>
            </ul>

            <h2>Unsere Trauerredner</h2>
            <div className="spotlight" style={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              gap: '30px',
            }}>
              <div>
                <span className="image" style={{marginLeft: 0}}>
                  <Gallery
                    images={[this.speaker[1]]}
                    mdColWidth={100}
                    colWidth={100}
                    imgClass="single-image-preview"
                  />
                </span>
                <p>Cathleen Rösler, geb. Bartko</p>
              </div>
              <div>
                <span className="image" style={{marginLeft: 0}}>
                    <Gallery
                      images={[this.speaker[0]]}
                      mdColWidth={100}
                      colWidth={100}
                      imgClass="single-image-preview"
                    />
                </span>
                <p>Dipl.-Ing. Björn Barthel</p>
              </div>
            </div>


            <center>
              <Link to="/" className="button">
                Zurück zur Übersicht
              </Link>
            </center>
          </section>
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query ImagesForSpeaker {
    speaker: allFile(
      filter: { relativeDirectory: { eq: "speaker" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              layout: CONSTRAINED
              transformOptions: {cropFocus: NORTH}
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
            meta: gatsbyImageData(layout: FIXED)
          }
        }
      }
    }
  }
`

export default Begleitung
